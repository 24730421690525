import {JWT} from "@aws-amplify/auth";

export enum AuthenticationStatus {
    Init = 'init',
    LoginVerified = 'loginVerified',
    LoggedIn = 'loggedIn',
    LoggedOut = 'loggedOut',
}

export type UserModel = {
    status: AuthenticationStatus,
    firstName?: string,
    lastName?: string,
    authentication?: UserAuthentication
}

export type UserAuthentication = {
    authToken: JWT,
    expiresIn?: number,
}
