import {signOut} from 'aws-amplify/auth';
import {AuthenticationStatus, UserModel} from "../model/userModel";
import React, {FC} from "react";

type NavbarProperties = {
    user: UserModel
}

const Navbar: FC<NavbarProperties> = (navbarProperties): React.JSX.Element => {

    const user = navbarProperties.user;
    const signOutUser = async () => {
        await signOut()
    }

    return (
        <div className="bg-blue-500 p-4 text-white flex justify-between min-h-16">
            <h1 className="font-bold text-xl">Expense Tracker</h1>

            {user.status === AuthenticationStatus.LoggedIn
                && <div>
                    <span className="mr-2">Hey {user.firstName}</span>
                    <button className="bg-blue-400 px-2 py-1 rounded hover:bg-blue-300 text-white"
                            onClick={() => signOutUser()}>Logout
                    </button>
                </div>
            }
        </div>
    );
}

export default Navbar;