import React, {FC, useState} from "react";
import {IconButton, SvgIconProps} from "@mui/material";
import RemoveCircleTwoToneIcon from "@mui/icons-material/RemoveCircleTwoTone";
import EditNoteTwoToneIcon from "@mui/icons-material/EditNoteTwoTone";
import {green, red} from "@mui/material/colors";
import ConfirmationDialogue from "../dialogues/ConfirmationDialogue";

export const TableActionButtons: FC<TableActionButtonsProps> = (props): React.JSX.Element => {

    const [confirmationOpen, setConfirmationOpen] = useState(false);

    const ShowMoreIcon = (props: SvgIconProps) => {
        return (<RemoveCircleTwoToneIcon {...props} fontSize="small"/>);
    }
    const EditIcon = (props: SvgIconProps) => {
        return (<EditNoteTwoToneIcon {...props} fontSize="small"/>);
    }

    const onDeleteClicked = () => {
        if (props.confirmDeletion === undefined || props.confirmDeletion) {
            setConfirmationOpen(true);
        } else {
            props.onDelete();
        }
    }

    const onConfirmationDialogueChoice = (accept: boolean) => {
        setConfirmationOpen(false);
        if (accept) {
            props.onDelete();
        }
    }

    return (
        <div>
            <IconButton aria-label="edit" onClick={props.onEdit}>
                <EditIcon sx={{color: green[300]}}/>
            </IconButton>
            <IconButton aria-label="delete" onClick={onDeleteClicked}>
                <ShowMoreIcon sx={{color: red[300]}}/>
            </IconButton>

            {props.confirmDeletion && confirmationOpen &&
                <React.Fragment>
                    <ConfirmationDialogue open={confirmationOpen} onUserChoice={onConfirmationDialogueChoice}
                                          titleText={props.deleteConfirmationTitle} confirmationText={props.deleteConfirmationText} />
                </React.Fragment>
            }
        </div>
    );
}

interface TableActions {
    onEdit: () => void
    onDelete: () => void,
    confirmDeletion: boolean
}

interface TableActionsWithDeleteConfirmation extends TableActions {
    confirmDeletion: true;
    deleteConfirmationTitle: string;
    deleteConfirmationText: string;
}

interface TableActionsWithoutDeleteConfirmation extends TableActions {
    confirmDeletion: false;
    deleteConfirmationTitle?: never;
    deleteConfirmationText?: never;
}

type TableActionButtonsProps = TableActionsWithDeleteConfirmation | TableActionsWithoutDeleteConfirmation;
