import styles from './Spinner.module.css';
import React, { FC } from "react";

const Spinner: FC<SpinnerProps> = (props): React.JSX.Element => {
    return (
        <div
            style = {{
                width: props.width,
                height: props.width
            }}
            className = { styles.loadingSpinner }
        >
            <div className = { styles.loadingSpinnerWheel }>
                <div
                    style = {{
                        transformOrigin: props.width * 0.4 + 'px ' + props.width * 0.41 + 'px',
                        boxShadow: `0 ${Math.min(props.width * 0.05, 4)}px 0 0 #e15b64`
                    }}
                    className = { styles.loadingSpinnerWheelInternal }
                ></div>
            </div>
        </div>
    );
}

interface SpinnerProps {
    width: number
}

export default Spinner;
