import { useContext } from "react";
import { ActionType, userContext } from "../context/userContext";
import { createUserAsset, CreateUserAssetRequest } from "../service/assetService";
import { STATUS_SUCCESS } from "../model/serverApiResponseModel";

export const useCreateAsset = () => {

    const { state, dispatch} = useContext(userContext);

    const addUserAsset = async (assetName: string, assetCurrency: string,
                                initialValue: number, onSuccess: () => void , onFailure: (error: string) => void) => {

        const createRequest: CreateUserAssetRequest = {
            assetName: assetName,
            currency: assetCurrency,
            assetType: 'currency',
            initialValue: initialValue
        }

        const response = await createUserAsset(createRequest, state.userState);
        if (response.status === STATUS_SUCCESS) {
            const updatedAssets = [...state.assetsState.userAssets, response.data];
            dispatch({ type: ActionType.SET_ASSETS_LOAD_COMPLETE, payload: updatedAssets });
            onSuccess();
        } else {
            onFailure(response.error);
        }
    };

    return { addUserAsset };
}