import {UseFormSetError} from "react-hook-form/dist/types/form";
import {ApiResponse, apiResponseWithError} from "../model/serverApiResponseModel";
import {createAuthHeader} from "./authenticationService";
import {UserModel} from "../model/userModel";

const VALIDATION_FAILED = "Validation failed: "
const ERROR_PATTERN = /(.*?):\s(.*?)(?=\n|$)/g;

export const SERVER_ERROR: any = "server";

export const onFormSubmissionFailure = (error: string, setError: UseFormSetError<any>, serverErrorCodeMap: Map<string, string>) => {
    if (!error.startsWith(VALIDATION_FAILED)) {
        setError(SERVER_ERROR, {
            type: SERVER_ERROR,
            message: error
        });
        return;
    }

    const withoutPrefix = error.replace(VALIDATION_FAILED, '');
    const errorMap: Record<string, string> = {};

    let match;
    while ((match = ERROR_PATTERN.exec(withoutPrefix) ) !== null) {
        errorMap[match[1]] = match[2].trim();
    }

    for (const [key, value] of Object.entries(errorMap)) {
        setError(serverErrorCodeMap.has(key) ? serverErrorCodeMap.get(key) : SERVER_ERROR, {
            type: SERVER_ERROR,
            message: value
        });
    }
}

export const fetchArray = async<Data> (endpoint: string, action: string, user: UserModel,
                                       converter: (data: any) => ApiResponse<Data[]>): Promise<ApiResponse<Data[]>> => {
    try {
        let response = await fetch(endpoint,
            {
                method: 'GET',
                headers: { Authorization: createAuthHeader(user.authentication) },
                credentials: 'include'
            });
        if (response.ok) {
            let data: any = await response.json();
            return converter(data);
        } else {
            return apiResponseWithError("Unable to " + action + ": " + response.statusText, action);
        }
    } catch (error) {
        return apiResponseWithError(error, action);
    }
}