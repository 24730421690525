import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {ColDef, ICellRendererParams} from "ag-grid-community";
import DataLoadingBanner from "../shared/DataLoadingBanner";
import NoDataBanner from "../shared/tables/NoDataBanner";
import {useLoadTransactions} from "../../hooks/transactions/useLoadTransactions";
import {TableHeader} from "../shared/tables/TableHeader";
import NewTransactionDialogue from "./NewTransactionDialogue";
import {TableActionButtons} from "../shared/tables/TableActionButtons";
import {useDeleteTransaction} from "../../hooks/transactions/useDeleteTransaction";
import {TransactionModel} from "../../model/transactionModel";
import SlideNotification from "../shared/SlideNotification";
import {UserModel} from "../../model/userModel";

const UserTransactions = () => {

    const { transactionState, loadUserTransactions } = useLoadTransactions();
    const { deleteUserTransaction } = useDeleteTransaction();

    const [ showAddNew, setShowAddNew] = useState<boolean>(false);
    const [ transactionInUpdate, setTransactionInUpdate] = useState<number | null>(null);
    const [ showNotification, setShowNotification ] = useState<boolean>(false);
    const [ notificationText, setNotificationText ] = useState<string>("");
    const [ notificationSeverity, setNotificationSeverity ] = useState<'success' | 'error'>("error");

    const gridRef = useRef<AgGridReact>(null);

    const reloadTransactions = useCallback((user: UserModel) => {
        let gridApi = gridRef?.current?.api;
        gridApi?.showLoadingOverlay();
        loadUserTransactions(user).then();
    }, [loadUserTransactions]);

    useEffect(() => {
        let gridApi = gridRef?.current?.api;
        if (transactionState.transactionLoadInProgress) {
            gridApi?.showLoadingOverlay();
        } else {
            if (transactionState.userTransactions.length > 0) {
                gridApi?.hideOverlay();
            } else {
                gridApi?.showNoRowsOverlay();
            }
        }
    }, [transactionState.transactionLoadInProgress, transactionState.userTransactions]);

    const TableActionsCellRenderer = (params: ICellRendererParams) => {
        return <TableActionButtons
            onEdit = {async () => {
                let transactionId = (params.data as TransactionModel).id;
                setTransactionInUpdate(transactionId)
            }}
            onDelete = {async () => {
                let transaction = params.data as TransactionModel;
                await deleteUserTransaction(transaction.id,
                    () => {
                        setNotificationText("Transaction successfully deleted");
                        setNotificationSeverity("success");
                        setShowNotification(true);
                    },
                    error => {
                        setNotificationText(error);
                        setNotificationSeverity("error");
                        setShowNotification(true);
                    }
                )
            }}
            confirmDeletion = {true}
            deleteConfirmationTitle = "Delete this transaction?"
            deleteConfirmationText = "This will delete selected transaction. This action cannot be undone. Are you sure you want to proceed?"
        />
    }

    const [columnDefs] = useState<ColDef[]>([
        { field: 'date', maxWidth: 120 },
        { field: 'amount', maxWidth: 150 },
        { field: 'category', maxWidth: 200 },
        { field: 'description', maxWidth: 500 },
        { field: '', maxWidth: 70, minWidth: 70, cellStyle: { 'padding': 0 },
            resizable: false, cellRenderer: TableActionsCellRenderer}
    ]);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 50,
            filter: false,
            sortable: false,
            resizable: true,
        };
    }, []);

    // No Data overlay
    const noDataOverlayParams = useMemo(() => {
        if (transactionState.transactionLoadError) {
            return {
                message: transactionState.transactionLoadError,
                onRetry: reloadTransactions
            };
        } else {
            return { message: "No transactions found for in the specified period" };
        }
    }, [transactionState.transactionLoadError, reloadTransactions]);

    const onAddTransaction = () => {
        setShowAddNew(true);
    }

    return (
        <div className="ag-theme-alpine">
            <TableHeader title="My Transactions" onAddNew={onAddTransaction} />
            <AgGridReact
                ref={gridRef}
                rowData={transactionState.userTransactions}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                loadingOverlayComponent={DataLoadingBanner}
                loadingOverlayComponentParams={{loadingMessage: 'Transactions are being loaded...'}}
                noRowsOverlayComponent={NoDataBanner}
                noRowsOverlayComponentParams={noDataOverlayParams}
                getRowId={data => data.data.id}
                domLayout='autoHeight'
            ></AgGridReact>

            { showAddNew &&
                <React.Fragment>
                    <NewTransactionDialogue onClose = {() => setShowAddNew(false)} transactionInUpdate = {null} />
                </React.Fragment>
            }

            { transactionInUpdate != null &&
                <React.Fragment>
                    <NewTransactionDialogue onClose = {() => setTransactionInUpdate(null)} transactionInUpdate = {transactionInUpdate} />
                </React.Fragment>
            }

            <React.Fragment>
                <SlideNotification
                    open = {showNotification}
                    onClose = {() => setShowNotification(false)}
                    text = {notificationText}
                    severity = {notificationSeverity}
                />
            </React.Fragment>
        </div>
    );
}

export default UserTransactions;
