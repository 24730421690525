import {useCallback, useContext, useEffect} from "react";
import {ActionType, userContext} from "../context/userContext";
import {AuthenticationStatus, UserModel} from "../model/userModel";
import {getAuthToken} from "../service/authenticationService";
import {Hub} from "aws-amplify/utils";

export const useLogin = () => {

    const { state, dispatch} = useContext(userContext);

    const processSignIn = useCallback(async () => {
        console.debug("Retrieving user JWT");
        const token = await getAuthToken();
        if (token) {
            const data = token.payload as any;
            const userDetails: UserModel = {
                status: AuthenticationStatus.LoggedIn,
                firstName: data.given_name,
                lastName: data.family_name,
                authentication: {
                    authToken: token,
                    expiresIn: token.payload.exp
                }
            }
            dispatch({ type: ActionType.ON_USER_LOGGED_IN, payload: userDetails });
        } else {
            dispatch({ type: ActionType.ON_USER_LOGGED_OUT })
        }
    }, [dispatch]);


    useEffect(() => {
        let userState = state.userState;
        if (userState.status === AuthenticationStatus.Init) {
            processSignIn().then();
        }
    }, [state.userState, dispatch, processSignIn]);


    useEffect(() => {
        return Hub.listen("auth", ({payload}) => {
            switch (payload.event) {
                case "signedIn":
                    break;
                case "signedOut":
                    dispatch({type: ActionType.ON_USER_LOGGED_OUT});
                    break;
                case "customOAuthState":
                    console.debug(payload.data); // this is the customState provided on signInWithRedirect function
                    break;
                case "signInWithRedirect_failure":
                    console.log("An error has occurred during the OAuth flow.")
                    break;
            }
        });
    }, [dispatch, processSignIn]);

    return { userState: state.userState, processSignIn: processSignIn };
}