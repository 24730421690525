import {Box, IconButton, Typography} from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import React from "react";

type TableHeaderProperties = {
    title: string;
    onAddNew: () => void;
};

export const TableHeader: React.FC<TableHeaderProperties> = ({ title, onAddNew }) => {
    return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" component="div">
                {title}
            </Typography>
            <IconButton onClick={onAddNew} color="primary">
                <FontAwesomeIcon icon={faPlus} />
            </IconButton>
        </Box>
    );
};