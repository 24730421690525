import {Controller, useForm} from "react-hook-form";
import { useCreateAsset } from "../../hooks/useCreateAsset";
import React, { FC, useState } from "react";
import DataLoadingBanner from "../shared/DataLoadingBanner";
import {onFormSubmissionFailure, SERVER_ERROR} from "../../service/serviceUtils";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    IconButton,
    TextField
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {ModalDialogueStyled} from "../shared/dialogues/modalDialogueStyled";

type NewAssetDialogueProps = {
    onClose: () => void
}

type NewAssetForm = {
    assetName: string,
    currency: string,
    initialAmount: number,
    server: string
};

const serverErrorCodeMap = new Map<string, string>([
    ["assetName", "assetName"],
    ["currency", "currency"],
    ["initialAmount", "initialAmount"]
]);

const NewAssetDialogue: FC<NewAssetDialogueProps> = (props): React.JSX.Element => {

    const { addUserAsset } = useCreateAsset();
    const [requestInProgress, setRequestInProgress] = useState<boolean>(false);

    const onSuccess = () => {
        reset();
    }

    const submitHandler = async (formData: NewAssetForm) => {
        setRequestInProgress(true);
        try {
            await addUserAsset(formData.assetName, formData.currency, formData.initialAmount, onSuccess,
                (error: string) => onFormSubmissionFailure(error, setError, serverErrorCodeMap));
        } finally {
            setRequestInProgress(false);
        }
    }

    const {
        control,
        handleSubmit,
        setError,
        formState: { errors },
        reset,
        clearErrors
    } = useForm<NewAssetForm>({
        defaultValues: {
            assetName: '',
            currency: '',
            initialAmount: 0
        },
        mode: "onChange"
    });

    return (
        <ModalDialogueStyled
            onClose={props.onClose}
            aria-labelledby="add-asset-dialogue"
            open={true}>

            <DialogTitle id="add-asset-dialogue" className="text-center">
                New Asset
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={props.onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>

            <form onSubmit={handleSubmit(submitHandler)}>

                <DialogContent dividers>
                    <div className="mb-7 mt-3">
                        <Controller
                            name="assetName"
                            control={control}
                            rules={{
                                required: 'Please enter asset name',
                                maxLength: {value: 20, message: "Maximum 20 characters allowed"}
                            }}
                            render={({field, fieldState: {error}}) => (
                                <TextField
                                    {...field}
                                    type="text"
                                    label="Asset Name*"
                                    placeholder="e.g. Bank account in EUR"
                                    className="w-full"
                                    size="small"
                                    error={!!error}
                                />
                            )}
                        />
                        {errors.assetName?.message &&
                            <FormHelperText className="w-full">
                                {errors.assetName.message}
                            </FormHelperText>
                        }
                    </div>

                    <div className="mb-7">
                        <Controller
                            name="currency"
                            control={control}
                            rules={{
                                required: 'Please enter currency name',
                                maxLength: {value: 20, message: "Maximum 20 characters allowed"}
                            }}
                            render={({field, fieldState: {error}}) => (
                                <TextField
                                    {...field}
                                    type="text"
                                    label="Currency*"
                                    placeholder="e.g. EUR"
                                    className="w-full"
                                    size="small"
                                    error={!!error}
                                />
                            )}
                        />
                        {errors.currency?.message &&
                            <FormHelperText className="w-full">
                                {errors.currency.message}
                            </FormHelperText>
                        }
                    </div>

                    <div className="mb-3">
                        <Controller
                            name="initialAmount"
                            control={control}
                            rules={{
                                pattern: {
                                    value: /^\d+(\.\d{1,2})?$/,
                                    message: 'Maximum two decimal places allowed'
                                },
                                min: {
                                    value: 0,
                                    message: "Initial amount cannot be less than zero"
                                }
                            }}
                            render={({field, fieldState: {error}}) => (
                                <TextField
                                    {...field}
                                    type="number"
                                    label="Initial Amount"
                                    className="w-full"
                                    size="small"
                                    error={!!error}
                                    InputProps={{
                                        inputProps: {
                                            step: "0.01",
                                        },
                                    }}
                                />
                            )}
                        />
                        {errors.initialAmount?.message &&
                            <FormHelperText className="w-full">
                                {errors.initialAmount.message}
                            </FormHelperText>
                        }
                    </div>

                    {errors.server?.message &&
                        <div className="w-full text-center mt-2">
                            <small className={"text-rose-600"}>{errors.server.message}</small>
                        </div>
                    }

                </DialogContent>

                <DialogActions className="my-3">
                    {requestInProgress &&
                        <div className="w-full m-auto">
                            <DataLoadingBanner/>
                        </div>
                    }
                    {!requestInProgress &&
                        <Button type="submit" variant="contained"
                                className="w-full m-auto"
                                onClick={() => clearErrors(SERVER_ERROR)}>
                            Create Asset
                        </Button>
                    }
                </DialogActions>
            </form>
        </ModalDialogueStyled>
    )
}

export default NewAssetDialogue;
