import React from 'react';
import './App.css';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import MainWindow from "./components/MainWindow";
import {ThemeProvider} from "@mui/material";
import {expensesTheme} from "./AppTheme";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

import '@aws-amplify/ui-react/styles.css';
import {UserContextProvider} from "./context/userContext";
import {configureAuthorization} from "./service/authenticationService";

function App() {
    configureAuthorization();

    return (
      <ThemeProvider theme={expensesTheme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
              <UserContextProvider>
                  <MainWindow />
              </UserContextProvider>
          </LocalizationProvider>
      </ThemeProvider>
    );
}

export default App;
