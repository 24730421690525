import React from "react";
import {signIn, signInWithRedirect} from "aws-amplify/auth";
import './AuthScreen.css';
import {Controller, useForm} from "react-hook-form";
import {Button, FormHelperText, TextField} from "@mui/material";
import {AuthError} from "@aws-amplify/auth/src/errors/AuthError";
import {useLogin} from "../../hooks/useLogin";


const AuthScreen = () => {

    const { processSignIn } = useLogin();

    type SignInForm = {
        email: string,
        password: string,
        authError: string
    };

    const signInWithUserData = async (formData: SignInForm) => {
        try {
            await signIn({
                username: formData.email,
                password: formData.password,
            });
            await processSignIn();
        } catch (error) {
            setError("authError", {
                type: "authError",
                message: (error as AuthError).message
            });
        }
    }

    // const resetPassword = async (formData: SignInForm) => {
    //     try {
    //         await confirmResetPassword({
    //             username: formData.email,
    //             confirmationCode: "11111",
    //             newPassword: "111111",
    //         });
    //     } catch (error) {
    //         setError("authError", {
    //             type: "authError",
    //             message: (error as AuthError).message
    //         });
    //     }
    // }

    const {
        control,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors
    } = useForm<SignInForm>({
        defaultValues: {
            email: '',
            password: ''
        },
        mode: "onChange"
    });

    return(
        <div className="text-center m-auto login-container">
            <div className="row">
                <h2 className="text-lg font-bold dark:text-white mb-2">Login with Social Media</h2>

                <div className="col">
                    <button className="fb btn my-2"
                            onClick={() => signInWithRedirect({
                                provider: "Facebook",
                                customState: "facebook-login"
                            })}>
                        Login with Facebook
                    </button>
                    <button className="google btn mt-2 mb-9"
                            onClick={() => signInWithRedirect({
                                provider: "Google",
                                customState: "google-login"
                            })}>
                        Login with Google
                    </button>

                    <hr className="border" />
                    <div>
                        <h3 className="text-lg font-bold dark:text-white mt-7 mb-2">Or sign in manually:</h3>
                    </div>

                    <form onSubmit={handleSubmit(signInWithUserData)}>
                        <div className="my-3">
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: 'Please enter your email address'
                                }}
                                render={({field, fieldState: {error}}) => (
                                    <TextField
                                        {...field}
                                        type="text"
                                        label="Email"
                                        className="btn"
                                        autoComplete="username"
                                        size="small"
                                        error={!!error}
                                    />
                                )}
                            />
                            {errors.email?.message &&
                                <FormHelperText className="min-h-5">
                                    {errors.email.message}
                                </FormHelperText>
                            }
                        </div>

                        <div className="my-3">
                            <Controller
                                name="password"
                                control={control}
                                rules={{
                                    required: 'Please enter your password'
                                }}
                                render={({field, fieldState: {error}}) => (
                                    <TextField
                                        {...field}
                                        type="password"
                                        autoComplete="current-password"
                                        label="Password"
                                        className="btn"
                                        size="small"
                                        error={!!error}
                                    />
                                )}
                            />
                            <FormHelperText className="min-h-5">
                                {errors.password?.message ? errors.password.message : ''}
                                {errors.authError?.message ? errors.authError?.message : ''}
                            </FormHelperText>
                        </div>
                            <Button type="submit" variant="contained" className="w-full m-auto"
                                    onClick={() => clearErrors('authError')}>
                                Login
                            </Button>
                    </form>
                </div>

            </div>
        </div>

    );
}

export default AuthScreen;