import {Action, ActionType} from "../Actions";
import {CategoryState} from "../categoryState";
import {CategoryModel} from "../../model/transactionModel";

export const categoryReducer = (state: CategoryState, action: Action): CategoryState => {

    switch (action.type) {

        case ActionType.ON_TRANSACTION_DELETED:
            return { ...state,
                userCategories: adjustCategoryUse(state.userCategories, action.payload.category, -1)
            }

        case ActionType.ON_TRANSACTION_ADDED:
            return { ...state,
                userCategories: adjustCategoryUse(state.userCategories, action.payload.category, 1)
            }

        case ActionType.ON_TRANSACTION_UPDATED:
            const updatedTransaction = action.payload.updated;
            const existingTransaction = action.payload.previous;

            if (updatedTransaction.category !== existingTransaction.category) {
                let updatedCategories = adjustCategoryUse(
                    state.userCategories, existingTransaction.category, -1);
                updatedCategories = adjustCategoryUse(updatedCategories, updatedTransaction.category, +1);
                return {...state,userCategories: updatedCategories }
            }

            return state

        case ActionType.SET_CATEGORIES:
                return { ...state,userCategories: action.payload }

        default:
            return state;
    }
};

const adjustCategoryUse = (userCategories: CategoryModel[], categoryName: string, increment: number) => {
    let existingCategory =
        userCategories.find(category => category.name === categoryName);

    if (!existingCategory) {
        if (increment > 0) {
            // If the category does not exist, ignore the action and return the current state
            const newCategory = {name: categoryName, frequency: 0};
            userCategories = [...userCategories, newCategory];
        } else {
            return userCategories;
        }
    }

    userCategories = userCategories
        .map(category => category.name === categoryName
            ? {...category, frequency: category.frequency + increment}
            : category);

    return userCategories.sort((a, b) => {
        const frequencyDifference = b.frequency - a.frequency;
        return frequencyDifference !== 0 ? frequencyDifference : a.name.localeCompare(b.name);
    });
}