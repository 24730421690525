import {Alert, Slide, SlideProps, Snackbar} from "@mui/material";
import React, {FC} from "react";

const SlideNotification: FC<SlideNotificationProps> = (props): React.JSX.Element => {
    const duration = props.autoHideDuration === undefined ? 5000 : props.autoHideDuration;
    const vertical = props.vertical === undefined ? 'top' : props.vertical;
    const horizontal = props.horizontal === undefined ? 'right' : props.horizontal;

    function SlideTransition(props: SlideProps) {
        return <Slide {...props} direction="down" />;
    }

    return (
        <Snackbar
            open={props.open}
            autoHideDuration={duration}
            onClose={props.onClose}
            anchorOrigin={{ vertical, horizontal }}
            TransitionComponent = {SlideTransition}>
            <Alert
                onClose={props.onClose}
                severity={props.severity}
                sx={{ width: '100%', marginTop: '45px' }}
            >
                {props.text}
            </Alert>
        </Snackbar>
    );
}

interface SlideNotificationProps {
    open: boolean,
    text: string,
    onClose: () => void
    severity: 'error' | 'success',
    autoHideDuration?: number,
    vertical?: 'top' | 'bottom';
    horizontal?: 'left' | 'center' | 'right';
}

export default SlideNotification;