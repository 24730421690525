import React, {FC} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

const ConfirmationDialogue: FC<ConfirmationDialogueProps> = (props): React.JSX.Element => {

    const randomId = "confirmation-dialogue-" + Math.random().toString(36).substring(2, 15);

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onUserChoice(false)}
            id={randomId}
            aria-labelledby={randomId}
            aria-describedby="confirmation-dialog-description"
        >
            <DialogTitle id={randomId}>
                {props.titleText}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.confirmationText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onUserChoice(false)}>Cancel</Button>
                <Button onClick={() => props.onUserChoice(true)} autoFocus>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}

interface ConfirmationDialogueProps {
    open: boolean,
    titleText: string,
    confirmationText: string,
    onUserChoice: (accept: boolean) => void
}

export default ConfirmationDialogue;