import {useContext} from "react";
import {ActionType, userContext} from "../../context/userContext";
import {STATUS_SUCCESS} from "../../model/serverApiResponseModel";
import {updateTransaction, UpdateTransactionRequest} from "../../service/transactionService";
import {formatDate} from "../../utils/dateFormatter";
import {Dayjs} from "dayjs";
import {TransactionModel} from "../../model/transactionModel";

export const useModifyTransaction = () => {

    const { state, dispatch} = useContext(userContext);

    const modifyTransaction = async (transaction: TransactionModel, assetId: number, date: Dayjs, amount: number, category: string,
                                  description: string, onSuccess: () => void , onFailure: (error: string) => void) => {
        const updateRequest: UpdateTransactionRequest = {
            transactionId: transaction.id,
            assetId: assetId,
            date: formatDate(date),
            amount: amount,
            category: category,
            description: description
        }

        const response = await updateTransaction(updateRequest, state.userState);
        if (response.status === STATUS_SUCCESS) {
            dispatch({
                type: ActionType.ON_TRANSACTION_UPDATED,
                payload: { updated: response.data, previous: transaction }
            });
            onSuccess();
        } else {
            onFailure(response.error);
        }
    };

    return { modifyTransaction };
}