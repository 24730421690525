import { createTheme } from "@mui/material";

const initialTheme = createTheme();

export const expensesTheme = createTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#3B82F6', // this is equivalent to Tailwind CSS .bg-blue-500
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        // secondary: will use the default color
        // error: will use the default color
    },
    components: {
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: initialTheme.palette.error.main,
                    textAlign: 'center'
                }
            }
        }
    }
});