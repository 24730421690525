import Spinner from "./Spinner";
import React, { FC } from "react";

const DataLoadingBanner: FC<LoadingBannerProps> = (props): React.JSX.Element => {
    return (
        <div className = "flex flex-col">
            <Spinner width = { 30 } />
            { props.loadingMessage &&
                <span className="w-full m-auto text-center">{ props.loadingMessage }</span>
            }
        </div>
    );
}

interface LoadingBannerProps {
    loadingMessage?: string
}

export default DataLoadingBanner;
