import React, {createContext, Dispatch, ReactNode, useCallback, useEffect, useReducer} from "react";
import {AssetState} from "./assetState";
import {AuthenticationStatus, UserModel} from "../model/userModel";
import {TransactionsState} from "./transactionsState";
import {CategoryState} from "./categoryState";
import {fetchUserCategories} from "../service/transactionService";
import {STATUS_SUCCESS} from "../model/serverApiResponseModel";
import {transactionReducer} from "./reducers/transactionReducer";
import {categoryReducer} from "./reducers/categoryReducer";
import {assetReducer} from "./reducers/assetReducer";
import {userStateReducer} from "./reducers/userStateReducer";
import {Action, ActionType} from "./Actions";

export interface State {
    userState: UserModel
    assetsState: AssetState
    transactionState: TransactionsState,
    categoryState: CategoryState
}

const initialState: State = {
    assetsState: {
        userAssets: [],
        assetLoadInProgress: false
    },
    transactionState: {
        userTransactions: [],
        transactionLoadInProgress: false
    },
    userState: {
        status: AuthenticationStatus.Init
    },
    categoryState: {
        userCategories: []
    }
};

const userContext = createContext<{
    state: State,
    dispatch: Dispatch<Action>
}>({
    state: initialState,
    dispatch: () => null,
});

const mainReducer = (state: State, action: Action): State => ({
    transactionState: transactionReducer(state.transactionState, action),
    categoryState: categoryReducer(state.categoryState, action),
    assetsState: assetReducer(state.assetsState, action),
    userState: userStateReducer(state.userState, action),
});

const UserContextProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(mainReducer, initialState);

    const loadCategories = useCallback(async (user: UserModel) => {
        const response = await fetchUserCategories(user);
        if (response.status === STATUS_SUCCESS) {
            dispatch({ type: ActionType.SET_CATEGORIES, payload: response.data });
        } else {
            console.log("Failed to load categories: " + response.error);
        }
    }, [dispatch]);

    useEffect(() => {
        if (state.userState.status === AuthenticationStatus.LoggedIn) {
            loadCategories(state.userState).then();
        }
    }, [dispatch, loadCategories, state.userState]);

    return (
        <userContext.Provider value = {{ state, dispatch }}>
            { children }
        </userContext.Provider>
    );
};

export { userContext, UserContextProvider, ActionType };
