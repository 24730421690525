import {Action, ActionType} from "../Actions";
import {AuthenticationStatus, UserModel} from "../../model/userModel";

export const userStateReducer = (state: UserModel, action: Action): UserModel => {
    switch (action.type) {

        case ActionType.ON_USER_LOGGED_IN:
            const userDetails = action.payload;
            return {...state,
                lastName: userDetails.lastName,
                firstName: userDetails.firstName,
                status: userDetails.status,
                authentication: userDetails.authentication
            }

        case ActionType.ON_USER_LOGGED_OUT:
            return { status: AuthenticationStatus.LoggedOut }

        default:
            return state;
    }
}