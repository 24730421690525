import React, { FC } from "react";

const NoDataBanner: FC<NoDataBannerProps> = (props): React.JSX.Element => {
    return (
        <div className="flex flex-col items-center justify-between">
            <span className="w-full mb-4">
                { props.message }
            </span>
            { props.onRetry &&
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4
                            rounded focus:outline-none focus:shadow-outline m-auto pointer-events-auto"
                        type = "button" onClick = { props.onRetry } disabled = {false}>
                    Retry
                </button>
            }
        </div>
    );
}

interface NoDataBannerProps {
    message: string,
    onRetry?: () => void
}

export default NoDataBanner;
