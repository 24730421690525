import UserAssets from "./assets/UserAssets";
import styles from './MainWindow.module.css';
import Navbar from "./Navbar";
import UserTransactions from "./transactions/UserTransactions";
import AuthScreen from "./auth/AuthScreen";
import {useLogin} from "../hooks/useLogin";
import {AuthenticationStatus} from "../model/userModel";

const MainWindow = () => {
    const { userState } = useLogin();
    return(
        <div className={"App flex flex-col min-h-screen bg-gray-100 " + styles.mainWindow}>
            <Navbar user = {userState} />

            {userState.status === AuthenticationStatus.LoggedIn
                &&
                <main className="flex-grow container mx-auto p-4">
                    <div className="flex flex-col lg:flex-row">
                        <div className="w-full p-4">
                            <UserTransactions/>
                        </div>
                        <div className="w-1/2 p-4">
                            <UserAssets/>
                        </div>
                    </div>
                </main>
            }

            {userState.status === AuthenticationStatus.LoggedOut
                &&
                <AuthScreen />
            }
        </div>
    );
}

export default MainWindow;
