import {Action, ActionType} from "../Actions";
import {AssetState} from "../assetState";

export const assetReducer = (state: AssetState, action: Action): AssetState => {
    switch (action.type) {
        case ActionType.SET_ASSETS_LOAD_IN_PROGRESS:
            return {...state, assetLoadInProgress: true}

        case ActionType.SET_ASSETS_LOAD_COMPLETE:
            return {...state, assetLoadInProgress: false, userAssets: action.payload}

        case ActionType.SET_ASSETS_LOAD_FAILURE:
            return {...state, assetLoadInProgress: false, assetLoadError: action.payload}

        default:
            return state;
    }
}