import {Action, ActionType} from "../Actions";
import {TransactionsState} from "../transactionsState";

export const transactionReducer = (state: TransactionsState, action: Action): TransactionsState => {
    switch (action.type) {

        case ActionType.SET_TRANSACTIONS_LOAD_IN_PROGRESS:
            return { ...state, transactionLoadInProgress: true }

        case ActionType.SET_TRANSACTIONS_LOAD_COMPLETE:
            return { ...state, transactionLoadInProgress: false, userTransactions: action.payload }

        case ActionType.SET_TRANSACTIONS_LOAD_FAILED:
            return { ...state, transactionLoadInProgress: false, transactionLoadError: action.payload }

        case ActionType.ON_TRANSACTION_DELETED:
            return { ...state, userTransactions: state.userTransactions.filter(
                    transaction => transaction.id !== action.payload.id) }

        case ActionType.ON_TRANSACTION_ADDED:
            return { ...state, userTransactions: [...state.userTransactions, action.payload] }

        case ActionType.ON_TRANSACTION_UPDATED:
            const updatedTransaction = action.payload.updated;
            const existingTransactionIndex = state.userTransactions
                .findIndex(transaction => transaction.id === updatedTransaction.id);

            if (existingTransactionIndex !== -1) {
                const updatedTransactions = state.userTransactions
                    .map(transaction => transaction.id === updatedTransaction.id ? updatedTransaction : transaction);
                return {...state, userTransactions: updatedTransactions }
            }
            return state;

        default:
            return state;
    }
};