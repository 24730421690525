import {ApiResponse, apiResponseWithError, STATUS_SUCCESS} from "../model/serverApiResponseModel";
import {
    CategoryListResponse,
    CategoryModel,
    TransactionListResponse,
    TransactionModel
} from "../model/transactionModel";
import {fetchArray} from "./serviceUtils";
import {createAuthHeader} from "./authenticationService";
import {UserModel} from "../model/userModel";

const TRANSACTION_API = "/transactions"
const CATEGORIES_ENDPOINT = "/categories"

export type NewTransactionRequest = {
    assetId: number,
    date: string,
    amount: number,
    category: string,
    description: string
};

export type UpdateTransactionRequest = NewTransactionRequest & {
    transactionId: number
}

export const fetchUserTransactions = async (user: UserModel): Promise<ApiResponse<TransactionModel[]>> => {
    return fetchArray(
        process.env.REACT_APP_EXPENSE_SERVICE + TRANSACTION_API + "?from=2024-02-01",
        "load transactions",
        user,
        (data: any) => {
            let transactions: TransactionListResponse = data as TransactionListResponse;
            return { data: transactions.transactions, status: STATUS_SUCCESS } as ApiResponse<TransactionModel[]>;
        }
    );
}

export const fetchUserCategories = async (user: UserModel): Promise<ApiResponse<CategoryModel[]>> => {
    return fetchArray(
        process.env.REACT_APP_EXPENSE_SERVICE + TRANSACTION_API + CATEGORIES_ENDPOINT,
        "load categories",
        user,
        (data: any) => {
            let categories: CategoryListResponse = data as CategoryListResponse;
            return { data: categories.categories, status: STATUS_SUCCESS } as ApiResponse<CategoryModel[]>;
        }
    );
}

export const createTransaction = async (request: NewTransactionRequest, user: UserModel): Promise<ApiResponse<TransactionModel>> => {
    return processTransactionRequest(
        process.env.REACT_APP_EXPENSE_SERVICE + TRANSACTION_API,
        'POST',
        JSON.stringify(request),
        'create',
        user
    )
}

export const updateTransaction = async (request: UpdateTransactionRequest, user: UserModel): Promise<ApiResponse<TransactionModel>> => {
    return processTransactionRequest(
        process.env.REACT_APP_EXPENSE_SERVICE + TRANSACTION_API + "/" + request.transactionId,
        'PUT',
        JSON.stringify(request),
        'modify',
        user
    )
}

export const deleteTransaction = async (transactionId: number, user: UserModel): Promise<ApiResponse<TransactionModel>> => {
    return processTransactionRequest(
        process.env.REACT_APP_EXPENSE_SERVICE + TRANSACTION_API + "/" + transactionId,
        'DELETE',
        null,
        'delete',
        user
    )
}

const processTransactionRequest = async (url: string, method: string,
         body: any, operation: string, user: UserModel): Promise<ApiResponse<TransactionModel>> => {
    try {
        let response = await fetch(url,
            {
                method: method,
                credentials: 'include',
                body: body,
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: createAuthHeader(user.authentication),
                }
            });

        let data: any = await response.json();
        return data as ApiResponse<TransactionModel>;
    } catch (error) {
        return apiResponseWithError(error, operation + " transaction");
    }
}