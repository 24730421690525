import React, {FC, useEffect, useState} from "react";
import DataLoadingBanner from "../shared/DataLoadingBanner";
import {onFormSubmissionFailure} from "../../service/serviceUtils";
import {useCreateTransaction} from "../../hooks/transactions/useCreateTransaction";
import {useModifyTransaction} from "../../hooks/transactions/useModifyTransaction";
import {Controller, useForm} from "react-hook-form";
import {
    Autocomplete,
    Button, ButtonGroup, DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    IconButton,
    MenuItem,
    TextField
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import {ModalDialogueStyled} from "../shared/dialogues/modalDialogueStyled";

type NewTransactionDialogueProps = {
    onClose: () => void
    transactionInUpdate: number | null
}

export type NewTransactionForm = {
    assetId: number,
    date: Dayjs,
    amount: number,
    category: string,
    description: string,
    server: string
};

const serverErrorCodeMap = new Map<string, string>([
    ["assetId", "assetId"],
    ["date", "date"],
    ["amount", "amount"],
    ["category", "category"],
    ["description", "description"]
]);

const NewTransactionDialogue: FC<NewTransactionDialogueProps> = (props): React.JSX.Element => {
    const { userCategories, userAssets, userTransactions, addTransaction } = useCreateTransaction();
    const { modifyTransaction } = useModifyTransaction();
    
    const transactionInUpdate = userTransactions.userTransactions
        .find(transaction => transaction.id === props.transactionInUpdate) || null;

    const [ requestInProgress, setRequestInProgress] = useState<boolean>(false);
    const [ isExpense, setIsExpense ] =
        useState<boolean>(transactionInUpdate != null ? transactionInUpdate.amount <= 0 : true);
    const [ submitAndClose, setSubmitAndClose] = useState<boolean>(true);

    const [ categories, setCategories ] = useState<string[]>([]);
    const [ defaultCategory, setDefaultCategory ] = useState('')

    const submitHandler = async (formData: NewTransactionForm) => {
        let amount = formData.amount;
        if (amount < 0 && !isExpense) {
            amount = Math.abs(amount);
        } else if (amount > 0 && isExpense) {
            amount = -amount;
        }

        setRequestInProgress(true);

        try {
            if (transactionInUpdate != null) {
                await modifyTransaction(transactionInUpdate, formData.assetId,
                    formData.date, amount, formData.category, formData.description,
                    () => props.onClose(),
                    (error: string) => onFormSubmissionFailure(error, setError, serverErrorCodeMap));
            } else {
                await addTransaction(formData.assetId, formData.date, amount, formData.category, formData.description,
                    () => {
                        reset();
                        if (submitAndClose) props.onClose();
                    },
                    (error: string) => onFormSubmissionFailure(error, setError, serverErrorCodeMap));
            }
        } finally {
            setRequestInProgress(false);
        }
    }

    const {
        control,
        handleSubmit,
        getValues,
        setValue,
        setError,
        formState: { errors },
        getFieldState,
        reset,
    } = useForm<NewTransactionForm>({
        defaultValues: {
            assetId: transactionInUpdate != null
                ? transactionInUpdate.asset.id
                : userAssets.userAssets.length > 0 ? userAssets.userAssets[0].id : undefined,
            date: transactionInUpdate != null ? dayjs(transactionInUpdate.date) : dayjs(),
            amount: transactionInUpdate != null ? transactionInUpdate.amount : 0,
            category: transactionInUpdate != null ? transactionInUpdate.category : defaultCategory,
            description: transactionInUpdate != null ? transactionInUpdate.description : ''
        },
        mode: 'onChange'
    });


    useEffect(() => {
        let categories = Array.from(userCategories.userCategories).map(category => category.name);
        let defaultCategory = categories[0] || '';
        setCategories(categories);
        setDefaultCategory(defaultCategory);

        if (getValues("category") === '' && !getFieldState("category").isTouched) {
            setValue("category", defaultCategory)
        }
    }, [getFieldState, getValues, setValue, userCategories]);

    return (

        <ModalDialogueStyled
            onClose={props.onClose}
            aria-labelledby="add-transaction-dialogue"
            open={true}>

            <DialogTitle id="add-transaction-dialogue" className="text-center">
                { props.transactionInUpdate != null ? 'Modify Transaction' : 'New Transaction' }
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={props.onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>

            <form onSubmit={handleSubmit(submitHandler)}>

                <DialogContent dividers>

                    <div className="mb-7 mt-3">
                        <Controller
                            name="assetId"
                            control={control}
                            rules={
                                {required: 'Please select an asset'}
                            }
                            render={({field, fieldState: {error}}) => (
                                <TextField
                                    {...field}
                                    select
                                    label="Amount*"
                                    className="w-full"
                                    size="small"
                                    error={!!error}
                                >
                                    {userAssets.userAssets.map((asset, index) => (
                                        <MenuItem key={index} value={asset.id}>
                                            {asset.assetName}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                        />

                        {errors.assetId?.message &&
                            <FormHelperText className="w-full">
                                {errors.assetId.message}
                            </FormHelperText>
                        }
                    </div>

                    <div className="mb-7">
                        <Controller
                            name="date"
                            control={control}
                            rules={{required: 'Please specify transaction date'}}
                            render={({field}) => (
                                <DatePicker
                                    {...field}
                                    format="LL"
                                    className="w-full"
                                    label="Date*"
                                />
                            )}
                        />
                        {errors.date?.message &&
                            <FormHelperText className="w-full">
                                {errors.date.message}
                            </FormHelperText>
                        }
                    </div>

                    <div className="mb-7">

                        <Controller
                            control={control}
                            name="category"
                            rules={{required: 'Please specify category',}}
                            render={({field, fieldState: {error}}) => (
                                <Autocomplete
                                    {...field}
                                    options={categories}
                                    disableClearable
                                    freeSolo
                                    size="small"
                                    value={field.value}
                                    onChange={(_, data) => field.onChange(data)}
                                    onInputChange={(_, data) => field.onChange(data)}
                                    renderInput={
                                        (params) =>
                                            <TextField
                                                {...params}
                                                label="Category*"
                                                placeholder="e.g. Groceries, Gas, Health"
                                                error={!!error}
                                            />
                                    }
                                />
                            )}
                        />

                        {errors.category?.message &&
                            <FormHelperText className="w-full">
                                {errors.category.message}
                            </FormHelperText>
                        }
                    </div>

                    <div className="mb-7">
                        <Controller
                            name="amount"
                            control={control}
                            rules={{
                                required: 'Amount is required',
                                pattern: {
                                    value: /^-?\d+(\.\d{1,2})?$/,
                                    message: 'Maximum two decimal places allowed'
                                }
                            }}
                            render={({field, fieldState: {error}}) => (
                                <div>
                                    <TextField
                                        {...field}
                                        type="number"
                                        label="Amount*"
                                        className="w-full"
                                        size="small"
                                        error={!!error}
                                        InputProps={{
                                            inputProps: {
                                                step: "0.01",
                                            },
                                        }}
                                    />
                                    <div className="text-center mt-2">
                                        <ButtonGroup variant="text" aria-label="Transaction type buttons" className="w-full">
                                            <Button
                                                variant = {isExpense ? "contained" : "outlined"}
                                                size="small" className="w-1/2"
                                                onClick={() => setIsExpense(true)}>
                                                Expense
                                            </Button>
                                            <Button
                                                variant = {isExpense ? "outlined" : "contained"}
                                                size="small" className="w-1/2"
                                                onClick={() => setIsExpense(false)}>
                                                Income
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                            )}
                        />
                        {errors.amount?.message &&
                            <FormHelperText className="w-full">
                                {errors.amount.message}
                            </FormHelperText>
                        }
                    </div>

                    <div className="my-3">
                        <Controller
                            name="description"
                            control={control}
                            render={({field, fieldState: {error}}) => (
                                <TextField
                                    {...field}
                                    type="text"
                                    label="Description"
                                    className="w-full"
                                    value={field.value}
                                    placeholder="i.e. Dinner on Friday night"
                                    size="small"
                                    error={!!error}
                                />
                            )}
                        />
                        {errors.description?.message &&
                            <FormHelperText className="w-full">
                                {errors.description.message}
                            </FormHelperText>
                        }
                    </div>

                    {errors.server?.message &&
                        <div className="w-full text-center mt-2">
                            <small className={"text-rose-600"}>{errors.server.message}</small>
                        </div>
                    }

                </DialogContent>

                <DialogActions className="my-3">
                    {requestInProgress &&
                        <div className="w-full m-auto">
                            <DataLoadingBanner/>
                        </div>
                    }
                    {!requestInProgress &&
                        <div className="w-full m-auto">
                            {props.transactionInUpdate == null &&
                                <div className="mb-3">
                                    <Button type="submit" name="create" variant="contained"
                                            className="w-full m-auto" onClick={() => setSubmitAndClose(false)}>
                                        Create
                                    </Button>
                                </div>
                            }
                            <div>
                                <Button type="submit" name="createAndClose" variant="contained"
                                        className="w-full m-auto" onClick={() => setSubmitAndClose(true)}>
                                    {props.transactionInUpdate != null ? 'Save' : 'Create and Close'}
                                </Button>
                            </div>
                        </div>
                    }
                </DialogActions>
            </form>
        </ModalDialogueStyled>
    )
}

export default NewTransactionDialogue;
