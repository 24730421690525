import {UserAssetModel, UserAssetListResponse} from "../model/userAssetModel";
import {ApiResponse, apiResponseWithError, STATUS_SUCCESS} from "../model/serverApiResponseModel";
import {fetchArray} from "./serviceUtils";
import {createAuthHeader} from "./authenticationService";
import {UserModel} from "../model/userModel";

const ASSETS_API = "/assets"

export type CreateUserAssetRequest = {
    assetName: string,
    currency: string,
    assetType: string,
    initialValue: number
}

export const fetchUserAssets = async (user: UserModel): Promise<ApiResponse<UserAssetModel[]>> => {
    return fetchArray(
        process.env.REACT_APP_EXPENSE_SERVICE + ASSETS_API,
        "load assets",
        user,
        (data: any) => {
            let userAssetsResponse: UserAssetListResponse = data as UserAssetListResponse;
            return { data: userAssetsResponse.assets, status: STATUS_SUCCESS } as ApiResponse<UserAssetModel[]>;
        }
    );
}

export const createUserAsset = async (request: CreateUserAssetRequest, user: UserModel): Promise<ApiResponse<UserAssetModel>> => {
    console.log("Sending new asset request");
    try {
        let response = await fetch(process.env.REACT_APP_EXPENSE_SERVICE + ASSETS_API,
            {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify(request),
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: createAuthHeader(user.authentication),
                }
            });

        let data: any = await response.json();
        return data as ApiResponse<UserAssetModel>;
    } catch (error) {
        return apiResponseWithError(error, "create asset");
    }
}
