export const STATUS_SUCCESS = "Success";
export const STATUS_FAILURE = "Failure";

export type ApiResponse<TData> =
    | {
        status: typeof STATUS_SUCCESS,
        data: TData
    }
    | {
        status: typeof STATUS_FAILURE,
        error: string,
    }

export const apiResponseWithError = <T>(error: any, action: string): ApiResponse<T> => {
    console.debug(error);
    return {
        status: STATUS_FAILURE,
        error: `Unable to ${action}. Please try again later.`
    };
}
