import {useContext} from "react";
import {ActionType, userContext} from "../../context/userContext";
import {STATUS_SUCCESS} from "../../model/serverApiResponseModel";
import {createTransaction, NewTransactionRequest} from "../../service/transactionService";
import {formatDate} from "../../utils/dateFormatter";
import {Dayjs} from "dayjs";

export const useCreateTransaction = () => {

    const { state, dispatch} = useContext(userContext);

    const addTransaction = async (assetId: number, date: Dayjs, amount: number, category: string,
                                description: string, onSuccess: () => void , onFailure: (error: string) => void) => {

        console.log("Using category value of '" + category + "'")
        const createRequest: NewTransactionRequest = {
            assetId: assetId,
            date: formatDate(date),
            amount: amount,
            category: category,
            description: description
        }

        const response = await createTransaction(createRequest, state.userState);
        if (response.status === STATUS_SUCCESS) {
            dispatch({ type: ActionType.ON_TRANSACTION_ADDED, payload: response.data });
            onSuccess();
        } else {
            onFailure(response.error);
        }
    };

    return { userCategories: state.categoryState, userAssets: state.assetsState, userTransactions: state.transactionState, addTransaction };
}