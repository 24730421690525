import {useCallback, useContext, useEffect} from "react";
import {ActionType, userContext} from "../../context/userContext";
import {STATUS_SUCCESS} from "../../model/serverApiResponseModel";
import {fetchUserTransactions} from "../../service/transactionService";
import {AuthenticationStatus, UserModel} from "../../model/userModel";

export const useLoadTransactions = () => {

    const { state, dispatch} = useContext(userContext);

    const loadUserTransactions = useCallback(async (user: UserModel) => {
        console.debug("Loading user transactions");
        dispatch( { type: ActionType.SET_TRANSACTIONS_LOAD_IN_PROGRESS } )
        const response = await fetchUserTransactions(user);
        if (response.status === STATUS_SUCCESS) {
            dispatch({ type: ActionType.SET_TRANSACTIONS_LOAD_COMPLETE, payload: response.data });
        } else {
            dispatch({ type: ActionType.SET_TRANSACTIONS_LOAD_FAILED, payload: response.error })
        }
    }, [dispatch]);

    useEffect(() => {
        if (state.userState.status === AuthenticationStatus.LoggedIn) {
            loadUserTransactions(state.userState).then();
        }
    }, [dispatch, loadUserTransactions, state.userState]);

    return { userState: state.userState, transactionState: state.transactionState, loadUserTransactions: loadUserTransactions };
}