import {fetchAuthSession, JWT} from '@aws-amplify/auth';
import {UserAuthentication} from "../model/userModel";
import {Amplify} from "aws-amplify";

export const createAuthHeader = (token: UserAuthentication | undefined): string => {
    return token ? 'Bearer ' + token.authToken : '';
}

export const getAuthToken = async(): Promise<JWT | undefined> => {
    try {
        const authSession = await fetchAuthSession({ forceRefresh: true });
        return authSession.tokens?.idToken;
    } catch (error) {
        console.error('error retrieving JWT, user might not be authenticated', error);
        return undefined;
    }
}

export const configureAuthorization = () => {
    Amplify.configure({
        Auth: {
            Cognito: {
                userPoolId: 'eu-central-1_Exv45DAID',
                userPoolClientId: '7945tuogl3fj1imrsqthsp27q2',
                identityPoolId: '',
                loginWith: {
                    oauth: {
                        domain: 'sunset-expenses-app.auth.eu-central-1.amazoncognito.com',
                        scopes: [
                            'email',
                            'profile',
                            'openid'
                        ],
                        redirectSignIn: [
                            'http://localhost:3000',
                            'https://ex.winesforgoodtimes.com'
                        ],
                        redirectSignOut: [
                            'http://localhost:3000'
                        ],
                        responseType: 'code'
                    },
                    email: true
                },
                passwordFormat: {
                    requireLowercase: true,
                    requireUppercase: true,
                    requireNumbers: true,
                    requireSpecialCharacters: true
                },
                allowGuestAccess: false,
                userAttributes: {
                    email: { required: true },
                    given_name: { required: true },
                    family_name: { required: true },
                },
                mfa: {
                    status: 'off'
                }
            }
        }
    });
}