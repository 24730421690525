import {Dialog, styled} from "@mui/material";

export const ModalDialogueStyled = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        minWidth: 370
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    },
}));