import {useCallback, useContext, useEffect} from "react";
import {ActionType, userContext} from "../context/userContext";
import {fetchUserAssets} from "../service/assetService";
import {STATUS_SUCCESS} from "../model/serverApiResponseModel";
import {AuthenticationStatus, UserModel} from "../model/userModel";

export const useLoadAssets = () => {

    const { state, dispatch} = useContext(userContext);

    const loadUserAssets = useCallback(async (user: UserModel) => {
        console.debug("Loading user assets");
        dispatch( { type: ActionType.SET_ASSETS_LOAD_IN_PROGRESS } )
        const response = await fetchUserAssets(user);
        if (response.status === STATUS_SUCCESS) {
            dispatch({ type: ActionType.SET_ASSETS_LOAD_COMPLETE, payload: response.data });
        } else {
            dispatch({ type: ActionType.SET_ASSETS_LOAD_FAILURE, payload: response.error })
        }
    }, [dispatch]);

    useEffect(() => {
        if (state.userState.status === AuthenticationStatus.LoggedIn) {
            loadUserAssets(state.userState).then();
        }
    }, [dispatch, loadUserAssets, state.userState]);

    return { userState: state.userState, assetsState: state.assetsState, loadUserAssets };
}