import {UserAssetModel} from "../model/userAssetModel";
import {UserModel} from "../model/userModel";
import {CategoryModel, TransactionModel, TransactionUpdateEvent} from "../model/transactionModel";

export enum ActionType {
    SET_ASSETS_LOAD_IN_PROGRESS = 'setAssetLoadInProgress',
    SET_ASSETS_LOAD_COMPLETE = 'setAssetLoadComplete',
    SET_ASSETS_LOAD_FAILURE = 'setAssetLoadFailure',
    ON_USER_LOGGED_IN = 'onUserLoggedIn',
    ON_USER_LOGGED_OUT = 'onUserLoggedInOut',
    SET_TRANSACTIONS_LOAD_IN_PROGRESS = 'setTransactionsLoadInProgress',
    SET_TRANSACTIONS_LOAD_COMPLETE = 'setTransactionsLoadComplete',
    SET_TRANSACTIONS_LOAD_FAILED = 'setTransactionsLoadFailed',
    ON_TRANSACTION_DELETED = "onTransactionDeleted",
    ON_TRANSACTION_ADDED = "onTransactionAdded",
    ON_TRANSACTION_UPDATED = "onTransactionUpdated",
    SET_CATEGORIES = "setCategories",
}

export type Action =
    | {
    type: ActionType.SET_ASSETS_LOAD_IN_PROGRESS,
}
    | {
    type: ActionType.SET_ASSETS_LOAD_COMPLETE,
    payload: UserAssetModel[],
}
    | {
    type: ActionType.SET_ASSETS_LOAD_FAILURE,
    payload: string,
}
    | {
    type: ActionType.ON_USER_LOGGED_IN,
    payload: UserModel
}
    | {
    type: ActionType.ON_USER_LOGGED_OUT,
}
    | {
    type: ActionType.SET_TRANSACTIONS_LOAD_IN_PROGRESS,
}
    | {
    type: ActionType.SET_TRANSACTIONS_LOAD_COMPLETE,
    payload: TransactionModel[],
}
    | {
    type: ActionType.SET_TRANSACTIONS_LOAD_FAILED,
    payload: string,
}
    | {
    type: ActionType.ON_TRANSACTION_DELETED,
    payload: TransactionModel,
}
    | {
    type: ActionType.ON_TRANSACTION_ADDED,
    payload: TransactionModel,
}
    | {
    type: ActionType.ON_TRANSACTION_UPDATED,
    payload: TransactionUpdateEvent,
}
    | {
    type: ActionType.SET_CATEGORIES,
    payload: CategoryModel[],
};