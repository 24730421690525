import {useContext} from "react";
import {ActionType, userContext} from "../../context/userContext";
import {STATUS_SUCCESS} from "../../model/serverApiResponseModel";
import {deleteTransaction} from "../../service/transactionService";

export const useDeleteTransaction = () => {

    const { state, dispatch} = useContext(userContext);

    const deleteUserTransaction = async (transactionId: number, onSuccess: () => void , onFailure: (error: string) => void) => {
        const response = await deleteTransaction(transactionId, state.userState);
        if (response.status === STATUS_SUCCESS) {
            dispatch({ type: ActionType.ON_TRANSACTION_DELETED, payload: response.data });
            onSuccess();
        } else {
            onFailure(response.error);
        }
    };

    return { state, deleteUserTransaction };
}