import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {ColDef, ICellRendererParams} from "ag-grid-community";
import DataLoadingBanner from "../shared/DataLoadingBanner";
import NoDataBanner from "../shared/tables/NoDataBanner";
import NewAssetDialogue from "./NewAssetDialogue";
import { useLoadAssets} from "../../hooks/useLoadAssets";
import {TableHeader} from "../shared/tables/TableHeader";
import React from "react";
import {TableActionButtons} from "../shared/tables/TableActionButtons";
import {UserAssetModel} from "../../model/userAssetModel";
import SlideNotification from "../shared/SlideNotification";
import {UserModel} from "../../model/userModel";

const UserAssets = () => {

    const { userState, assetsState, loadUserAssets } = useLoadAssets();
    const [ showAddNew, setShowAddNew] = useState<boolean>(false);
    const [ showErrorNotification, setShowErrorNotification ] = useState<boolean>(false);
    const [ errorNotificationText ] = useState<string>("");

    const gridRef = useRef<AgGridReact>(null);

    const reloadUserAssets = useCallback((user: UserModel) => {
        let gridApi = gridRef?.current?.api;
        gridApi?.showLoadingOverlay();
        loadUserAssets(user).then();
    }, [loadUserAssets]);

    useEffect(() => {
        let gridApi = gridRef?.current?.api;
        if (assetsState.assetLoadInProgress) {
            gridApi?.showLoadingOverlay();
        } else {
            if (assetsState.userAssets.length > 0) {
                gridApi?.hideOverlay();
            } else {
                gridApi?.showNoRowsOverlay();
            }
        }
    }, [assetsState.assetLoadInProgress, assetsState.userAssets]);

    const TableActionsCellRenderer = (params: ICellRendererParams) => {
        return <TableActionButtons
            onEdit = {() => console.log("On Edit")}
            onDelete = {async () => {
                let asset = params.data as UserAssetModel;
                console.log("Deleting asset " + asset.assetName)
            }}
            confirmDeletion = {true}
            deleteConfirmationTitle = "Delete this asset?"
            deleteConfirmationText = {"This will delete selected asset and all the associated transactions. " +
                "This action cannot be undone. Are you sure you want to proceed?"}
        />
    }

    const [columnDefs] = useState<ColDef[]>([
        { field: 'assetName', headerName: 'Asset', maxWidth: 200, width: 100 },
        { field: 'startBalance', headerName: 'Start', maxWidth: 120 },
        { field: 'endBalance', headerName: 'End', maxWidth: 120 },
        { field: '', maxWidth: 70, minWidth: 70, cellStyle: { 'padding': 0 }, resizable: false,
            cellRenderer: TableActionsCellRenderer}
    ]);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 50,
            filter: false,
            sortable: false,
            resizable: true,
        };
    }, []);

    // No Data overlay
    const noDataOverlayParams = useMemo(() => {
        if (assetsState.assetLoadError) {
            return {
                message: assetsState.assetLoadError,
                onRetry: reloadUserAssets(userState)
            };
        } else {
            return { message: "You haven't added any assets yet" };
        }
    }, [assetsState.assetLoadError, reloadUserAssets, userState]);

    return (
        <div className="ag-theme-alpine">
            <TableHeader title="My Assets" onAddNew={() => setShowAddNew(true)} />
            <AgGridReact
                ref={gridRef}
                rowData={assetsState.userAssets}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                loadingOverlayComponent={DataLoadingBanner}
                loadingOverlayComponentParams={{loadingMessage: 'Your assets are being loaded...'}}
                noRowsOverlayComponent={NoDataBanner}
                noRowsOverlayComponentParams={noDataOverlayParams}
                getRowId={data => data.data.id}
                domLayout='autoHeight'
            ></AgGridReact>

            {showAddNew &&
                <React.Fragment>
                    <NewAssetDialogue onClose={() => setShowAddNew(false)} />
                </React.Fragment>
            }

            <SlideNotification
                open = {showErrorNotification}
                onClose = {() => setShowErrorNotification(false)}
                text = {errorNotificationText}
                severity="error"
            />

        </div>
    );
}

export default UserAssets;
